export const TOKEN_KEY = 'id_token';
export const OFFICE_KEY = 'officeId';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const config = {
  apiBaseUrl: API_BASE_URL,
  socketServer: SOCKET_URL,
};

export const jsonHeaders = { 'Content-Type': 'application/json' };

export const authHeaders = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  return {
    ...jsonHeaders,
    'Authorization': `Bearer ${token}`
  }
};

export default config;
