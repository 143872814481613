import { Observable } from 'rxjs';
import * as io from 'socket.io-client';

import { TOKEN_KEY, SOCKET_URL } from '../config';

class SocketService {

  constructor() {
    this.instance = null;
    this.authenticated = null;
  }

  followOffice(officeId) {
    return Observable.create((observer) => {
      // Emit next observable value
      const emit = (data) => {
        let type = Object.keys(data)[0];
        observer.next({type: type, data: data[type]});
      };

      this.createSocket().then(socket => {

        this.instance.emit(`follow-office`, { id: officeId });
        this.instance.on(`new-call`, emit);
        this.instance.on('call-event', emit);

        this.instance.on('reconnect', (attemptNumber) => {
          this.instance.emit('follow-office', { id: officeId });
        });
      });

      // Unsubscribe from future events
      const dispose = () => {
        if (this.instance) {
          this.instance.off('new-call', emit);
          this.instance.off('call-event', emit);
          this.instance.emit('unfollow-office', { id: officeId });
        }
      };

      return dispose;
    });
  }

  createSocket() {

    if (this.authenticated) {
      return this.authenticated;
    }

    this.authenticated = new Promise((resolve, reject) => {

      this.instance = io(SOCKET_URL, { transports: ['websocket'] });

      this.instance.on('reconnect_attempt', () => {
        this.instance.io.opts.transports = ['polling', 'websocket'];
      });

      this.instance.on('connect', () => {
        const token = localStorage.getItem(TOKEN_KEY);

        this.instance.emit('authenticate', { token });
      });

      this.instance.on('connect_error', (error) => {
        reject(error);
      });

      this.instance.on('authenticated', () => {
        resolve(this.instance);
      });

      this.instance.on('unauthorized', (msg) => {
        reject(msg);
      });

    });

    return this.authenticated;
  }

}

export default new SocketService();
