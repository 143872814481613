import React from 'react';
import './CallListItem.scss';

function Patient({patient, toBeCalledAgain}) {
  return (
    <div className="patient">
      <div className="last-name">
        <span>{patient.last_name}</span>
        <span className="to-be-called-again">
          {toBeCalledAgain ? 'Volverá a ser llamado' : ''}
        </span>
      </div>
      <div className="first-name">{patient.first_name}</div>
    </div>
  );
}

function Office({officeRoom}) {
  return (
    <div className="office-room">
      <div className="number">{officeRoom.description}</div>
      <div className="name">{officeRoom.equipment}</div>
    </div>
  )
}

export default function CallListItem({call}) {
  return (
    <li className="call-item">
      <Patient patient={call.patient || {}} toBeCalledAgain={call.toBeCalledAgain}/>
      <Office officeRoom={call.office_room || {}}/>
    </li>
  );
}
