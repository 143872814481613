import { of as observableOf, timer, empty } from 'rxjs';
import {
  map, mergeMap, concatMap, delayWhen,
  pairwise, first, startWith
} from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { getCalls } from '../api/calls.api';
import { GET_CALLS, NEW_CALL_RECEIVED, getCallsSuccess, showCall } from '../actions';

export const getCallsEpic = (getCalls$) => getCalls$.pipe(
  ofType(GET_CALLS),
  mergeMap(getCalls),
  map(getCallsSuccess)
);

const secondsElapsed = (milliseconds) => {
  return ([previousAction, currentAction]) => {
    const diff = currentAction.timestamp - previousAction.timestamp;

    if (previousAction.isFirst || diff > milliseconds) {
      return empty();
    } else {
      return timer(milliseconds - diff).pipe(first());
    }
  }
};

export const newCallSuccessEpic = (actions$) => {
  const newCall$ = actions$.pipe(
    ofType(NEW_CALL_RECEIVED),
    map(action => ({ action, timestamp: new Date().getTime() }))
  );

  return newCall$.pipe(
    startWith({ action: null, timestamp: new Date().getTime(), isFirst: true }),
    pairwise(),
    concatMap((actions) => {
      return observableOf(actions).pipe(
        delayWhen((actions) => secondsElapsed(10000)(actions)),
        map(([_, {action}]) => action.call)
      );
    }),
    map(showCall)
  )
};
