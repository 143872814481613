import { mergeMap, mapTo, map, tap } from 'rxjs/operators';
import { push } from 'connected-react-router'
import { ofType } from 'redux-observable';

import { login, authenticated, logout, saveToken } from '../api/login.api';
import {
  LOGIN, LOGIN_SUCCESS, CHECK_AUTH, LOGOUT,
  selectOffice, loginError, loginSuccess
} from '../actions';

export const loginEpic = (login$) => login$.pipe(
  ofType(LOGIN),
  mergeMap(action => login(action.user, action.password)),
  map((response) => {
    return response.success ? loginSuccess(response.data) : loginError();
  })
);

export const checkAuthEpic = (checkAuth$) => checkAuth$.pipe(
  ofType(CHECK_AUTH),
  mergeMap(() => authenticated()),
  map((isAuthenticated) => {
    return isAuthenticated ? selectOffice() : push('/login');
  })
);

export const loginSuccessEpic = (loginSuccess$) => loginSuccess$.pipe(
  ofType(LOGIN_SUCCESS),
  tap((action) => saveToken(action.user.id_token)),
  mapTo(push('/seleccion-sucursal'))
);

export const logoutEpic = (logout$) => logout$.pipe(
  ofType(LOGOUT),
  mergeMap(() => logout()),
  mapTo(push('/login'))
);
