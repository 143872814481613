import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from '../actions';

const initialState = {
  authenticated: false
};

export default function login(state = initialState, action) {

  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authenticated: false,
        error: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        error: false
      };
    case LOGIN_ERROR:
      return {
        ...state,
        authenticated: false,
        error: true
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        error: false
      };
    default:
      return state;
  }
}
