import { GET_CALLS_SUCCESS, GET_CALLS, SHOW_CALL, TO_BE_CALLED_AGAIN } from '../actions';

const initialState = {
  lastCall: {},
  calls: []
};

export default function calls(state = initialState, action) {
  switch (action.type) {
    case GET_CALLS:
      return {
        calls: []
      };
    case GET_CALLS_SUCCESS:
      const calls = action.calls;

      return {
        lastCall: calls[0],
        calls: calls.slice(1)
      };
    case SHOW_CALL:
      const newCall = action.call;
      const lastCall = state.lastCall;
      // Keep only last 20 calls
      const oldCalls = state.calls.slice(0, 19);

      if (lastCall && lastCall.id) {
        oldCalls.unshift(lastCall);
      }

      return {
        lastCall: newCall,
        calls: oldCalls
      };
    case TO_BE_CALLED_AGAIN:
      const _lastCall = state.lastCall;
      const _calls = state.calls;

      if (_lastCall && _lastCall.id === action.callId) {
        _lastCall.toBeCalledAgain = true;
      } else {
        const call = _calls.find(c => c.id === action.callId);
        if (call) {
          call.toBeCalledAgain = true;
        }
      }

      return {
        lastCall: {..._lastCall},
        calls: _calls.map(c => ({...c}))
      };
    default:
      return state;
  }
}
