import { of as observableOf } from 'rxjs';
import { mergeMap, map, mapTo, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router'

import { getOffice, getOffices, getSelectedOffice, removeOffice, saveOffice } from '../api/office.api';

import {
  GET_OFFICES, SELECT_OFFICE, OFFICE_SELECTED, UNSET_OFFICE,
  getOfficesSuccess, getCalls, officeSelected
} from '../actions';

export const getOfficesEpic = (getOffices$) => getOffices$.pipe(
  ofType(GET_OFFICES),
  mergeMap(getOffices),
  map(getOfficesSuccess)
);

export const selectOfficeEpic = (selectOffice$) => selectOffice$.pipe(
  ofType(SELECT_OFFICE),
  mergeMap(getSelectedOffice),
  mergeMap(officeId => {
    return officeId ? getOffice(officeId) : observableOf(null);
  }),
  map(office => {
    return office ? officeSelected(office) : push('/seleccion-sucursal');
  })
);

export const officeSelectedEpic = (officeSelected$) => officeSelected$.pipe(
  ofType(OFFICE_SELECTED),
  tap((action) => saveOffice(action.office.id)),
  mergeMap(action => observableOf(
    getCalls(action.office.id),
    push('/board')
  ))
);

export const unsetOfficeEpic = (unsetOffice$) => unsetOffice$.pipe(
  ofType(UNSET_OFFICE),
  mergeMap(() => removeOffice()),
  mapTo(push('/seleccion-sucursal'))
);
