import React from 'react';

import './LastCall.scss';
import bell_sound  from '../../assets/media/bell.mp3';

const Patient = ({patient}) => (
  <div className="patient">
    <span className="last-name">{patient.last_name}&nbsp;</span>
    <span className="first-name">{patient.first_name}&nbsp;</span>
  </div>
);

const Office = ({officeRoom}) => (
  <div className="office-room">
    <div className="description">
      { officeRoom.description }&nbsp;
    </div>
    <div className="equipment">
      { officeRoom.equipment }&nbsp;
    </div>
  </div>
);

export function LastCall({call}) {

  return (
    <div className="last-call">
      <audio autoPlay><source src={bell_sound} type="audio/mpeg" ></source></audio>
      <Patient patient={call.patient || {}} />
      <Office officeRoom={call.office_room || {}} />
    </div>
  );
}
