import { map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { API_BASE_URL, authHeaders } from '../config';

export function getCalls({officeId}) {
  return ajax({
    url : `${API_BASE_URL}/offices/${officeId}/study_calls`,
    crossDomain: true,
    headers: authHeaders()
  }).pipe(
    map(e => e.response),
    map(response => response.study_calls)
  );
}
