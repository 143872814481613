import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions';
import userDefault from '../../assets/default-user.png'
import logo from '../../assets/logo-tcmax.png'

import './Login.scss';

export class Login extends Component {

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.setState({user: '', password: ''});
  }

  handleInputChange(event) {
    const target = event.target;
    const [value, name] = [target.value, target.name];

    this.setState({
      [name]: value
    });
  }

  render() {
    const loginButton = (
      <button
        type='button'
        onClick={() => { this.props.login(this.state.user, this.state.password) }}
      >
        Login
      </button>
    );

    const error = <div className="error-message">Usuario o clave incorrectos</div>;

    return (
      <div className="login">
        <div className="form-container">
          <img src={logo} alt="logo" className="logo" />
          <form className="form">
            <img src={userDefault} alt="logo" className="user-default" />
            <div className="input-div">
              <input type="text" id="name" name="user" placeholder="Email" onChange={this.handleInputChange}/>
            </div>
            <div className="input-div">
              <input type="password" id="password" name="password" placeholder="Contraseña" onChange={this.handleInputChange} />
            </div>
            { this.props.error && error }
            <div className="actions">{loginButton}</div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.loginState.error
  };
};

export default connect(
  mapStateToProps,
  { login }
)(Login);
