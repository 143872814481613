import React, { Component } from 'react';
import { connect } from 'react-redux';

import './OfficeSelector.scss';
import { getOffices, officeSelected } from '../../actions';

export class OfficeSelector extends Component {

  componentDidMount() {
    this.props.getOffices();
  }

  render() {

    return (
      <div className="office-selector">
        <div>
          <h1>
            Seleccione una Sucursal
          </h1>
        </div>
        <div className="list-offices">
            {this.props.offices.map((office) =>
              <li className="box-office" key={office.id} onClick={() => this.props.officeSelected(office) }>
                {office.name}
              </li>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offices: state.officeState.offices
  };
};

export default connect(
  mapStateToProps,
  { getOffices, officeSelected }
)(OfficeSelector);
