import {
  GET_OFFICE_SUCCESS, GET_OFFICES_SUCCESS,
  OFFICE_SELECTED
} from '../actions';

const initialState = {
  office: {},
  offices: []
};

export default function office(state = initialState, action) {

  switch (action.type) {
    case GET_OFFICE_SUCCESS:
      return {
        ...state,
        office: action.office
      };
    case GET_OFFICES_SUCCESS:
      return {
        ...state,
        offices: action.offices
      };
    case OFFICE_SELECTED:
      return {
        ...state,
        office: action.office
      };
    default:
      return state;
  }
}
