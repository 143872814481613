// Constant definitions

export const GET_OFFICE_SUCCESS  = "GET_OFFICE_SUCCESS";
export const GET_OFFICES         = "GET_OFFICES";
export const GET_OFFICES_SUCCESS = "GET_OFFICES_SUCCESS";
export const SELECT_OFFICE       = "SELECT_OFFICE";
export const OFFICE_SELECTED     = "OFFICE_SELECTED";
export const UNSET_OFFICE        = 'UNSET_OFFICE';

// Actions creators

export function getOfficeSuccess(office) {
  return {
    type: GET_OFFICE_SUCCESS,
    office
  };
}

export function getOffices() {
  return { type: GET_OFFICES };
}

export function getOfficesSuccess(offices) {
  return {
    type: GET_OFFICES_SUCCESS,
    offices
  };
}

export function selectOffice() {
  return { type: SELECT_OFFICE };
}

export function officeSelected(office) {
  return { type: OFFICE_SELECTED, office };
}

export function unsetOffice() {
  return {
    type: UNSET_OFFICE
  };
}
