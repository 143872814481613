import { createStore, applyMiddleware, compose } from 'redux'
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import rootReducer from './reducers';
import rootEpic from './epics';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

const store = createStore(
  rootReducer(history),
  {},
  compose(
    applyMiddleware(
      routerMiddleware(history),
      epicMiddleware
    )
  )
);

epicMiddleware.run(rootEpic);

export default store;
