import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';

import './Board.scss';
import { LastCall } from '../LastCall/LastCall';
import CallList from '../CallList/CallList';
import socketClient from '../../api/socket-client';
import { newCallReceived, toBeCalledAgain } from '../../actions';

export class Board extends Component {

  constructor(props) {
    super(props)

    this.subscribeToCallsUpdates = this.subscribeToCallsUpdates.bind(this);
    this.unSubscribeFromUpdates = this.unSubscribeFromUpdates.bind(this);
  }

  subscribeToCallsUpdates(officeId) {
    this.subscription = officeId && socketClient.followOffice(officeId).subscribe((response) => {
      if (response.type === 'call') {
        this.props.newCallReceived(response.data);
      } else if (response.type === 'event' && response.data.type === 'going-called-again') {
        this.props.toBeCalledAgain(response.data.callId)
      }
    });
  }

  unSubscribeFromUpdates() {
    this.subscription && this.subscription.unsubscribe();
  }

  componentDidMount() {
    // Unsubscribe form updates events
    const officeId = this.props.office && this.props.office.id;
    this.subscribeToCallsUpdates(officeId);
  }

  componentDidUpdate(prevProps) {
    //On office changes, unsubscribe from previous, subscribe to new one
    if (this.props.office && prevProps.office && this.props.office.id !== prevProps.office.id) {
      this.unSubscribeFromUpdates();
      this.subscribeToCallsUpdates(this.props.office.id);
    }
  }

  componentWillUnmount() {
    this.unSubscribeFromUpdates();
  }

  render() {
    return (
      <div className="board">
        <CSSTransitionGroup
          transitionName="blink"
          transitionAppear={false}
          transitionEnter={true}
          transitionEnterTimeout={3500}
          transitionLeave={false}>
          <LastCall key={this.props.lastCall.id} call={this.props.lastCall}/>
        </CSSTransitionGroup>

        <div className="alert-message">
         <marquee scrolldelay='70' truespeed='true'> Le recordamos retirarse todos los elementos metálicos del cuello para arriba. Si esta embarazada o cree estarlo, por favor comuníquelo en recepción. Muchas Gracias! </marquee>
        </div>

        <div className="content">
          <div className="history">
            <CallList calls={this.props.calls} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lastCall: state.callsState.lastCall || {},
    calls: state.callsState.calls
  };
};

export default connect(
  mapStateToProps,
  {newCallReceived, toBeCalledAgain}
)(Board);
