import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { OFFICE_KEY, API_BASE_URL, authHeaders } from '../config';

export function getOffice(id) {
  return ajax({
    url : `${API_BASE_URL}/offices/${id}`,
    crossDomain: true,
    headers: authHeaders()
  }).pipe(
    map(e => e.response),
    map(response => response.office)
  );
}

export function getOffices() {
  return ajax({
    url : `${API_BASE_URL}/offices`,
    crossDomain: true,
    headers: authHeaders()
  }).pipe(
    map(e => e.response),
    map(response => response.offices)
  );
}

export function getSelectedOffice() {
  const officeId = localStorage.getItem('officeId');
  return observableOf(officeId);
}

export function removeOffice() {
  localStorage.removeItem('officeId');

  return observableOf(true);
}

export function saveOffice(officeId) {
  localStorage.setItem(OFFICE_KEY, officeId);

  return observableOf(true);
}
