import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import officeState from './office.reducer';
import callsState from './calls.reducer';
import loginState from './login.reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  officeState,
  callsState,
  loginState
});
