// Constant definitions

export const GET_CALLS          = "GET_CALLS";
export const GET_CALLS_SUCCESS  = "GET_CALLS_SUCCESS";
export const NEW_CALL_RECEIVED  = "NEW_CALL_RECEIVED";
export const SHOW_CALL          = "SHOW_CALL";
export const TO_BE_CALLED_AGAIN = "TO_BE_CALLED_AGAIN";

// Actions creators

export function getCalls(officeId) {
  return { type: GET_CALLS, officeId };
}

export function getCallsSuccess(calls) {
  return {
    type: GET_CALLS_SUCCESS,
    calls
  };
}

export function newCallReceived(call) {
  return { type: NEW_CALL_RECEIVED, call };
}

export function showCall(call) {
  return {
    type: SHOW_CALL,
    call
  };
}

export function toBeCalledAgain(callId) {
  return {
    type: TO_BE_CALLED_AGAIN,
    callId
  };
}
