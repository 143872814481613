import { of as observableOf } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';

import { API_BASE_URL, TOKEN_KEY, jsonHeaders } from '../config';

export function login(email, password) {
  return ajax({
    url : `${API_BASE_URL}/session`,
    crossDomain: true,
    method: 'POST',
    body: { user: { email, password }, app: 'BOARD' },
    headers: jsonHeaders
  }).pipe(
    map(e => e.response),
    catchError(error => observableOf({error: true, success: false}))
  );
}

export function authenticated() {
  const token = localStorage.getItem(TOKEN_KEY);

  return observableOf(token && true);
}

export function logout() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('officeId');

  return observableOf(true);
}

export function saveToken(token) {
  localStorage.setItem(TOKEN_KEY, token);

  return observableOf(true);
}
