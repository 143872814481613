// Constant definitions

export const LOGIN         = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR   = 'LOGIN_ERROR';
export const LOGOUT        = 'LOGOUT';
export const CHECK_AUTH    = 'CHECK_AUTH';

// Actions creators

export function login(user, password) {
  return {
    type: LOGIN,
    user,
    password
  };
}

export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    user
  };
}

export function loginError() {
  return {
    type: LOGIN_ERROR
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

export function checkAuth() {
  return {
    type: CHECK_AUTH
  };
}
