import React from 'react';
import { connect } from 'react-redux';

import logo from '../../assets/tcmax-logo-header.jpg'
import './Header.scss'
import { logout, unsetOffice } from '../../actions';

export function Header({office, logout, unsetOffice}) {

  return (
    <div className="header">
      <div className="logo-column">
        <img
          src={logo}
          onClick={() => logout() }
          alt="logo"
          className="logo"
          title="Salir"
        />
      </div>
      <div className="office-name">
        <h1
          onClick={() => unsetOffice() }
          title="Cambiar sucursal"
        >{office.name}</h1>
      </div>
    </div>
  );
}

export default connect(
  null,
  { logout, unsetOffice }
)(Header);
