import { combineEpics } from 'redux-observable';

import { getOfficesEpic, selectOfficeEpic, officeSelectedEpic, unsetOfficeEpic } from './office.epic';
import { getCallsEpic, newCallSuccessEpic } from './calls.epic';
import { loginEpic, loginSuccessEpic, checkAuthEpic, logoutEpic } from './login.epic';

const rootEpic = combineEpics(
  getOfficesEpic,
  selectOfficeEpic,
  officeSelectedEpic,
  unsetOfficeEpic,
  getCallsEpic,
  newCallSuccessEpic,
  loginEpic,
  loginSuccessEpic,
  checkAuthEpic,
  logoutEpic
);

export default rootEpic;
