import React from 'react';

import './CallList.scss';
import CallListItem from '../CallListItem/CallListItem'

export default function CallList({calls}) {
  return (
    <ul className="call-list">
      {calls.map(call => <CallListItem key={call.id} call={call} />)}
    </ul>
  );
}
