import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Switch, Redirect } from 'react-router'

import './App.scss';
import Board from './components/Board/Board'
import Header from './components/Header/Header'
import Login from './components/Login/Login'
import OfficeSelector from './components/OfficeSelector/OfficeSelector';
import { checkAuth } from './actions';

export class App extends Component {

  componentDidMount() {
    this.props.checkAuth();
  }

  render() {

    const AppComponent = (
      <div className="App">
        <Header office={this.props.office}/>
        <Board office={this.props.office} />
      </div>
    );

    return (
      <div className="App">
        <Switch>
          <Route path="/board" exact render={() => AppComponent } />
          <Route path="/login" exact render={() => <Login />} />
          <Route path="/seleccion-sucursal" exact render={() => <OfficeSelector />} />
          <Redirect from="/" exact to="/board" />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    office: state.officeState.office
  };
};

export default withRouter(connect(
  mapStateToProps,
  {checkAuth}
)(App));
